// HomePanel1.js
import React from 'react';
import { Container } from 'react-bootstrap';

const HomePanel1 = () => {
  return (
    <Container fluid style={{ backgroundColor: 'rgb(29, 29, 29)' }} className="text-white px-4 py-8">
      <div className="max-w-7xl mx-auto">
        <nav className="flex justify-end space-x-6 mb-12">
          <a href="#" className="text-lg hover:text-green-500 border-b-2 border-transparent hover:border-green-500 transition duration-300 ease-in-out">Mine</a>
          <a href="#" className="text-lg hover:text-green-500 border-b-2 border-transparent hover:border-green-500 transition duration-300 ease-in-out">Use</a>
          <a href="#" className="text-lg hover:text-green-500 border-b-2 border-transparent hover:border-green-500 transition duration-300 ease-in-out">Wallet</a>
        </nav>

        <div className="flex flex-col lg:flex-row">
          {/* Image block - visible only on mobile */}
          <div className="w-full lg:hidden mb-8">
            <div className="bg-[rgb(29,29,29)] rounded-lg p-4">
              <div className="flex justify-center items-center">
                <img src="/dlm.png" alt="DLM" className="w-full h-auto" />
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/2 pr-0 lg:pr-8 space-y-8 mb-8 lg:mb-0">
          <div className="bg-gradient-to-r from-purple-600 to-pink-500 text-white px-4 py-2 rounded-full inline-block">
              <span className="text-4xl lg:text-5xl font-bold">SWARM</span>
            </div>
            <div className="font-bold">
              <div className="text-3xl lg:text-5xl">Decentralized Learning Machine</div>
            </div>

            <p className="text-lg lg:text-xl">
            SWARM DLM Network represents a paradigm shift for decentralized AI Infrastructure
            </p>
            <div className="flex space-x-4">
              <button className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">
                Mine
              </button>
              <button className="bg-green-500 hover:bg-green-600 text-white px-6 py-2 rounded">
                Use
              </button>
            </div>
          </div>

          {/* Image block - visible only on desktop */}
          <div className="hidden lg:block w-full lg:w-1/2">
            <div className="bg-transparent">
              <div className="flex justify-center items-center h-80">
                <img src="/dlm.png" alt="DLM" className="w-3/4 h-auto" />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12">
          <p className="text-lg lg:text-3xl leading-relaxed">
            Our DLM layer transforms gaming and rendering class GPUs into secure, high-performance AI clusters, offering decentralized, affordable, and secure compute infrastructure for all.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default HomePanel1;