import React from 'react';

const HomePanel2 = () => {
  return (
    <div style={{ backgroundColor: 'rgb(13, 13, 13)' }} className="text-white px-4 py-12">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col lg:flex-row mb-12">
          {/* Left column */}
          <div className="w-full lg:w-1/2 pr-0 lg:pr-8 mb-8 lg:mb-0">
            <h2 className="text-5xl font-bold mb-4">Mine SWARM Tokens</h2>
            <h3 className="text-3xl mb-4">Build the Future of AI</h3>
            <br></br>
            <p className="text-lg mb-6">
              By deploying a DLM node in a datacenter<br />
              or your home you power the AI builders and<br />
              creators, and earn SWARM tokens.
            </p>
            <br></br> <br></br>
            <button className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-md text-lg">
              Start Now
            </button>
          </div>
          
          {/* Right column with images */}
          <div className="w-full lg:w-1/2 flex justify-center items-center">
            <img src="/rtx.png" alt="RTX GPU" className="w-1/2 mr-4" />
            <img src="/h100.png" alt="H100 GPU" className="w-1/2 ml-4" />
          </div>
        </div>
        
        {/* Three rounded boxes */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-gray-700 p-6 rounded-xl">
            <h4 className="font-bold text-xl mb-3">Step 1</h4>
            <p>Decide on your level of commitment, you can acquire anywhere from a fraction of a GPU to multiple GPUs You can also connect your home GPUs to our network.</p>
          </div>
          <div className="bg-gray-700 p-6 rounded-xl">
            <h4 className="font-bold text-xl mb-3">Step 2</h4>
            <p>The SWARM DLM layer combines the nodes into a cluster and users can rent those as simple Linux machines or buy from our serverless AI offerings.</p>
          </div>
          <div className="bg-gray-700 p-6 rounded-xl">
            <h4 className="font-bold text-xl mb-3">Step 3</h4>
            <p>Earn SWARM tokens based on your level of contribution. You can earn additional tokens by referring others and playing our Tap2Earn Telegram game.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePanel2;