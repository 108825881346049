import React from 'react';
import { Cpu, Network, Users } from 'lucide-react';

const HomePanel3 = () => {
    return (
      <div style={{ backgroundColor: 'rgb(29, 29, 29)' }} className="text-white px-4 py-12">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-5xl font-bold mb-8 text-center">DLM User Services</h2>
          
          <div className="flex justify-center">
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-full lg:w-2/3">
              <div className="grid grid-cols-1 gap-4">
                <div className="bg-blue-500 p-4 rounded-lg text-center">
                  <h3 className="text-xl font-bold">RAG as a Service (RAAS)</h3>
                  <p>Make your unstructured data available for Retrieval Augmented Generation</p>
                </div>
                <div className="bg-green-500 p-4 rounded-lg text-center">
                  <h3 className="text-xl font-bold">Training as a Service (TAAS)</h3>
                  <p>Train your AI models using our decentralized infrastructure</p>
                </div>
                <div className="bg-orange-500 p-4 rounded-lg text-center">
                  <h3 className="text-xl font-bold">Inference as a Service (IAAS)</h3>
                  <p>Run your AI models with high performance and low latency</p>
                </div>
                <div className="bg-purple-500 p-4 rounded-lg text-center">
                  <h3 className="text-xl font-bold">Rent Linux GPU Machines</h3>
                  <p>Access a grid of GPUs for your compute needs</p>
                </div>
              </div>
            </div>
          </div>

        <br></br>
          {/* New "Join Waitlist" button */}
          <div className="text-center">
            <button className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-md text-lg">
              Join Waitlist
            </button>
          </div>
        </div>
      </div>
    );
  };

export default HomePanel3;