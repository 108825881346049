import React from 'react';
import HomePanel1 from './HomePanel1';
import HomePanel2 from './HomePanel2';
import HomePanel3 from './HomePanel3';

function App() {
  return (
    <div className="App">
      <HomePanel1 />
      <HomePanel2 />
      <HomePanel3 />
    </div>
  );
}

export default App;